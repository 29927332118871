<script lang="ts" setup>
import { inject } from 'vue'
import { useRoute } from 'vue-router'
import { $busEmit } from '@kidzonet/inject-bus-emit'
import { MainMenuItemInterface } from '../types'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface MenuLinksProps {
    menu: MainMenuItemInterface[]
}

const props = defineProps<MenuLinksProps>()

const route = useRoute()
const isActiveItem = (menuItem: MainMenuItemInterface) => {
    if (menuItem.to === undefined) {
        return
    }
    if (typeof menuItem.to === 'string') {
        return route.path === menuItem.to
    }
    return route.name === menuItem.to.name || route?.meta?.menuItem === menuItem.to.name
}

const busEmit = inject($busEmit)

const click = (item: MainMenuItemInterface) => {
    if (busEmit && isActiveItem(item)) {
        busEmit('click_on_current_menu_item')
    }
}

const buttonClass = (menuItem: MainMenuItemInterface) => {
    const baseClass = 'common-layer-menu__menu-item'
    if (isActiveItem(menuItem)) {
        return `${baseClass} common-layer-menu__menu-item-active`
    }
    return baseClass
}
</script>

<template>
    <v-toolbar-items class="common-layer-menu hidden-sm-and-down">
        <v-btn
            :active="isActiveItem(item)"
            color="#fff"
            v-for="item in props.menu"
            :key="String(item.to) || item.href"
            :to="item.to"
            :href="item.href"
            :class="buttonClass(item)"
            @click="click(item)"
        >
            <v-icon
                v-if="item.prependIcon"
                :icon="item.prependIcon"
            />
            {{ item.title }}
            <v-icon
                v-if="item.appendIcon"
                :icon="item.appendIcon"
            />
        </v-btn>
    </v-toolbar-items>
</template>

<style>
    .common-layer-menu {
        &__menu-item {
            /* fix active menu item color */
            &.theme--light.v-btn--active::before,
            &.theme--dark.v-btn--active::before {
                opacity: 0;
            }

            &.v-btn--active:hover::before {
                opacity: 0.04;
            }

            &--active.theme--light {
                &.v-btn--active::before,
                &.v-btn--active:hover::before {
                    opacity: 0.12;
                }
            }

            &--active.theme--dark {
                &.v-btn--active::before,
                &.v-btn--active:hover::before {
                    opacity: 0.24;
                }
            }

            .v-btn__content {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
</style>
