<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'

const { t } = useI18n()
const store = useAuthStore()

store.getCurrrentUserInfo()

useClickOnCurrentMenuItemListener(store.getCurrrentUserInfo)
</script>

<template>
    <div class="account-user-info">
        <h2 class="account-user-info__description">
            {{ store.fullname || store.user.username }}
        </h2>
        <div>
            <div class="account-user-info__description">
                <div class="account-user-info__label">
                    {{ t('web_common.account.info.id') }}:
                </div>
                <div
                    id="account-user-description-id"
                    class="text-grey"
                >
                    {{ store.user.id }}
                </div>
            </div>
            <div class="account-user-info__description">
                <div class="account-user-info__label">
                    {{ t('web_common.account.info.email') }}:
                </div>
                <div class="text-grey">
                    {{ store.user.email }}
                </div>
            </div>
            <div
                v-if="store.user.identityNumber"
                class="account-user-info__description"
            >
                <div class="account-user-info__label">
                    {{ t('web_common.account.info.identity_number') }}:
                </div>
                <div class="text-grey">
                    {{ store.user.identityNumber }}
                </div>
            </div>
            <div
                v-if="store.user.passport"
                class="account-user-info__description"
            >
                <div class="account-user-info__label">
                    {{ t('web_common.account.info.passport') }}:
                </div>
                <div class="text-grey">
                    {{ store.user.passport }}
                </div>
            </div>
            <div
                v-if="store.user.homeAddress"
                class="account-user-info__description"
            >
                <div class="account-user-info__label">
                    {{ t('web_common.account.info.home_address') }}:
                </div>
                <div class="text-grey">
                    {{ store.user.homeAddress }}
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .account-user-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: 1em;

        word-break: break-word;

        &__description {
            display: flex;
            flex-wrap: wrap;
        }

        &__label {
            inline-size: 150px;
            padding-inline-end: 1em;

            @media (max-width: 1100px) {
                inline-size: auto;
            }

            @media (max-width: 700px) {
                inline-size: 100px;
            }
        }
    }
</style>
