import samples, { requestType } from '..'

export default (host: string) => ({
    '/v1/public/auth/auth_info': {
        get get () {
            const url = `https://${host}/v1/public/auth/auth_info`
            return samples({ url })
        },
    },
    '/v1/public/auth/login': {
        get post () {
            const url = `https://${host}/v1/public/auth/login`
            const useToken = false
            const method = requestType.POST
            const data = '{"username":"test","password":"test"}'
            return samples({
                url, method, data, useToken,
            })
        },
    },
    '/v1/public/auth/refresh': {
        get post () {
            const url = `https://${host}/v1/public/auth/refresh`
            const method = requestType.POST
            const data = '{"refresh_token":"test"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/auth/revoke': {
        get post () {
            const url = `https://${host}/v1/public/auth/revoke`
            const method = requestType.POST
            const data = '{"token":"test"}'
            return samples({
                url, method, data,
            })
        },
    },
    '/v1/public/auth/revoke_all': {
        get post () {
            const url = `https://${host}/v1/public/auth/revoke_all`
            const method = requestType.POST
            return samples({
                url, method,
            })
        },
    },
    '/v1/public/auth/sessions': {
        get get () {
            const url = `https://${host}/v1/public/auth/sessions`
            return samples({ url })
        },
    },
    '/v1/public/auth/sessions/{session_id}': {
        get delete () {
            const url = `https://${host}/v1/public/auth/sessions/1`
            return samples({ url, method: requestType.DELETE })
        },
    },
    '/v1/public/auth/short_refresh_token': {
        get get () {
            const url = `https://${host}/v1/public/auth/short_refresh_token`
            return samples({ url })
        },
    },
    '/v1/public/auth/signup': {
        get post () {
            const url = `https://${host}/v1/public/auth/signup`
            const method = requestType.POST
            const data = '{"email":"test@test.test","username":"test","first_name":"test","last_name":"test","password":"test","password2":"test"}'
            return samples({
                url, method, data, useToken: false,
            })
        },
    },
    '/v1/public/auth/user': {
        get post () {
            const url = `https://${host}/v1/public/auth/user`
            const method = requestType.POST
            const data = ['{',
                '"id": "10",',
                '"email": "test@test.test",',
                '"username": "test",',
                '"role": "customer",',
                '"external_id": "test",',
                '"first_name": "test",',
                '"last_name": "test",',
                '"phone": "test",',
                '"identity_number": "test",',
                '"passport_number": "test",',
                '"home_address": "test",',
                '"is_active": true',
                '}',
            ].join('')
            return samples({
                url, method, data,
            })
        },
    },
    get '/v1/public/auth/user/{id}' () {
        const url = `https://${host}/v1/public/auth/user/1`
        return {
            get: samples({ url }),
            delete: samples({ url, method: requestType.DELETE }),
        }
    },
    '/v1/public/auth/user/{id}/sessions': {
        get get () {
            const url = `https://${host}/v1/public/auth/user/1/sessions`
            return samples({ url })
        },
    },
    get '/v1/public/auth/user/{user_id}' () {
        const url = `https://${host}/v1/public/auth/user/1`
        const data = ['{',
            '"id": "10",',
            '"email": "test@test.test",',
            '"username": "test",',
            '"role": "customer",',
            '"external_id": "test",',
            '"first_name": "test",',
            '"last_name": "test",',
            '"phone": "test",',
            '"identity_number": "test",',
            '"passport_number": "test",',
            '"home_address": "test",',
            '"is_active": true',
            '}',
        ].join('')
        return {
            put: samples({ url, method: requestType.PUT, data }),
            patch: samples({ url, method: requestType.PATCH, data }),
        }
    },
    '/v1/public/auth/user/{user_id}/login': {
        get post () {
            const url = `https://${host}/v1/public/auth/user/1/login`
            const method = requestType.POST
            return samples({
                url, method,
            })
        },
    },
    '/v1/public/auth/user/{user_id}/set_password': {
        get post () {
            const url = `https://${host}/v1/public/auth/user/1/set_password`
            const method = requestType.POST
            const data = '{"user_id":"30","password":"test"}'
            return samples({
                url, method, data,
            })
        },
    },
})
