<script lang="ts" setup>
import { PropType, defineComponent } from 'vue'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@kidzonet/pinia/UserStore'

import CommonLayer from '@kidzonet/vue3-common-layer/src/CommonLayer/index.vue'
import { MainMenuInterface } from '@kidzonet/vue3-common-layer/src/types'

interface RouterRootProps {
    root: string
    menu: MainMenuInterface[]
}

const props = defineProps<RouterRootProps>()

const store = useAuthStore()
await store.fetchUserInfo()
</script>

<template>
    <CommonLayer
        :menu="menu"
        :root="root"
    >
        <template #before-logo>
            <slot name="before-logo" />
        </template>
        <template #logo>
            <slot name="logo" />
        </template>
    </CommonLayer>
</template>

<style>
    .app-content {
        padding: calc(1em + 64px) 2em 1em;
    }
</style>
