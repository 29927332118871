<script lang="ts" setup>
import { computed } from 'vue'
import { useIsRtl } from '@kidzonet/vue-i18n-package'
import { MainMenuInterface } from '../types'

defineProps<{
    menu: MainMenuInterface[]
}>()
const isRtl = computed(() => useIsRtl())
</script>

<template>
    <div class="drawer-mini-menu">
        <div
            v-for="item in menu"
            :key="item.title"
        >
            <v-menu
                open-on-hover
                :right="!isRtl"
                :left="isRtl"
                offset-x
                tile
            >
                <template #activator="{ props }">
                    <v-btn
                        class="drawer-mini-menu__item-button"
                        v-bind="props"
                        icon
                        :to="item.to"
                        :href="item.href"
                        tile
                    >
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                </template>

                <v-list
                    color="mainMenu"
                    tile
                >
                    <v-list-item
                        :to="item.to"
                        :href="item.href"
                    >
                        <v-list-item-title class="drawer-mini-menu__item-title">
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-for="subItem in (item.children || [])"
                        :key="subItem.title"
                        :to="subItem.to"
                        :href="subItem.href"
                    >
                        <v-list-item-title class="drawer-mini-menu__item-title">
                            {{ subItem.title }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<style>
    .drawer-mini-menu {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &__item-button {
            inline-size: 100% !important;
            margin: 0.5em 0;
        }

        &__item-title {
            color: #fff;
        }
    }
</style>
