<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import ApiDocs from '@kidzonet/vue3-redoc'

const { t } = useI18n()

const schemes = computed(() => [{
    title: t('web_common.api.scheme.auth_v1'),
    to: 'auth_v1',
}, {
    title: t('web_common.api.scheme.dashboard_v1'),
    to: 'dashboard_v1',
}, {
    title: t('web_common.api.scheme.isp'),
    to: 'isp_v1',
}, {
    title: t('web_common.api.scheme.kznctlAuth'),
    to: 'kznctlAuth',
}, {
    title: t('web_common.api.scheme.billing'),
    to: 'billing',
}, {
    title: t('web_common.api.scheme.dbExplorer'),
    to: 'dbExplorer',
}])
</script>

<template>
    <ApiDocs
        back="/apps"
        :menu="schemes"
        :one-line="false"
    />
</template>
