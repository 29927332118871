<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import LanguageSelector from './LanguageSelector.vue'
import DarkModeSwitcher from './DarkModeSwitcher.vue'

const { t } = useI18n()
</script>

<template>
    <div class="account-settings">
        <h2>{{ t('web_common.account.settings.header') }}</h2>
        <div class="account-settings__dynamic-content">
            <LanguageSelector />
            <DarkModeSwitcher />
        </div>
        <slot />
    </div>
</template>

<style>
    .account-settings {
        &__dynamic-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            inline-size: 20em;
            min-block-size: 136px;
        }
    }
</style>
