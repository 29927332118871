<script lang="ts" setup>
interface AppListItemProps {
    name: string
    link: string
}

const props = defineProps<AppListItemProps>()
</script>

<template>
    <a
        class="list-item"
        :href="props.link"
    >
        <slot />
        <span>{{ props.name }}</span>
    </a>
</template>

<style>
    .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: 3em;

        color: rgb(var(--v-theme-primary));
        text-transform: capitalize;

        transition: 1s;

        &:hover {
            transform: scale(1.3);
        }
    }
</style>
