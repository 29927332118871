<script lang="ts" setup>
import { MainMenuItemInterface } from '../types'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface ListItemProps {
    item: MainMenuItemInterface
}

const props = defineProps<ListItemProps>()

defineEmits(['click'])
</script>

<template>
    <v-list-item
        class="drawer-menu-item"
        :prepend-icon="props.item.icon"
        :to="props.item.to"
        :href="props.item.href"
        @click="$emit('click')"
    >
        <v-list-item-title class="drawer-menu-item__title">
            {{ props.item.title }}
        </v-list-item-title>
    </v-list-item>
</template>

<style>
    .drawer-menu-item {
        color: #fff !important;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1) !important;
        }

        &__title {
            color: #fff;
            text-transform: capitalize;
        }
    }
</style>
