<script lang="ts" setup>
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@kidzonet/pinia/UserStore'
import MenuLinks from './MenuLinks.vue'
import DrawerMenu from '../DrawerMenu/index.vue'
import Preloader from './Preloader.vue'
import { MainMenuItemInterface } from '../types'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface CommonLayerProps {
    menu: MainMenuItemInterface[]
    initialDrawer?: boolean
    contentClass?: string
    loaded?: boolean
    root?: string | { name: string }
    oneLine?: boolean
}

const props = withDefaults(defineProps<CommonLayerProps>(), {
    initialDrawer: false,
    contentClass: '',
    loaded: true,
    root: '/dashboard',
    oneLine: true,
})

const { t } = useI18n()

const store = useAuthStore()
const loading = computed(() => {
    return store.isLoading && props.loaded
})

const isOffline = ref(navigator.onLine === false)
const setOffline = () => {
    isOffline.value = true
}
const setOnline = () => {
    isOffline.value = false
}

const drawer = ref(props.initialDrawer)

onMounted(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
})
onUnmounted(() => {
    window.removeEventListener('online', setOnline)
    window.removeEventListener('offline', setOffline)
})
</script>

<template>
    <div class="layer">
        <slot name="menu">
            <v-navigation-drawer
                fixed
                app
                temporary
                v-model="drawer"
                class="main-menu"
            >
                <DrawerMenu
                    :menu="menu"
                />
            </v-navigation-drawer>
            <v-app-bar
                color="mainMenu"
                fixed
            >
                <v-app-bar-nav-icon
                    class="layer__burger-icon"
                    @click.stop="drawer = !drawer"
                />
                <v-toolbar-title class="layer__title">
                    <slot name="before-logo-link" />
                    <router-link
                        :to="root"
                        class="layer__home-link"
                    >
                        <slot name="before-logo" />
                        <slot name="logo">
                            <img
                                class="layer__logo"
                                src="~@kidzonet/assets/src/layer/menu-logo.png"
                                alt="logo"
                            >
                        </slot>
                        <slot name="after-logo" />
                    </router-link>
                </v-toolbar-title>
                <v-spacer class="layer__spacer" />
                <MenuLinks
                    v-if="oneLine"
                    :menu="menu"
                />
                <template
                    v-if="!oneLine"
                    #extension
                >
                    <MenuLinks
                        :menu="menu"
                    />
                </template>
            </v-app-bar>
        </slot>
        <v-main>
            <slot name="content">
                <v-container
                    fluid
                    :class="['layer__content', contentClass]"
                >
                    <v-alert
                        v-if="isOffline"
                        type="warning"
                    >
                        {{ t('errors.offline') }}
                    </v-alert>
                    <Preloader v-if="loading" />
                    <slot
                        name="top"
                        :loading="loading"
                    />
                    <router-view />
                </v-container>
            </slot>
        </v-main>
    </div>
</template>

<style>
    .layer {
        &__logo {
            inline-size: 10em;
            block-size: 100%;
            margin-block-start: 1em;
            margin-inline-start: 2em;

            @media (max-width: 960px) {
                block-size: 2.1em;
                margin-block-start: 0.5em;
            }

            @media (max-width: 360px) {
                margin-inline-start: 0;
            }
        }

        &__burger-icon {
            display: none !important;
            color: #fff !important;

            @media (max-width: 960px) {
                display: block !important;
            }
        }
        &__content {
            block-size: 100%;
            padding: 2em;

            @media (max-width: 960px) {
                padding-block-end: 3em;
            }
        }

        &__spacer {
            @media (max-width: 960px) {
                display: none;
            }
        }
    }

    .main-menu,
    .v-navigation-drawer__content {
        max-inline-size: 100vw;
        background-color: rgb(var(--v-theme-mainMenu));
    }

    .v-navigation-drawer__scrim {
        position: fixed;
    }
</style>
