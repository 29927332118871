<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from '@kidzonet/pinia'
import { useTheme } from 'vuetify'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@kidzonet/pinia/UserStore'

import ServiceApp from './item.vue'

const store = useAuthStore()
const { t } = useI18n()

const { isAdmin, isSuperUser, isManager } = storeToRefs(store)

const kidzonetStaff = computed(() => isSuperUser.value || isManager.value || isAdmin.value)

const canViewInvoicing = kidzonetStaff
const canViewDBExplorer = kidzonetStaff
const canViewStaff = kidzonetStaff
const canViewAPI = kidzonetStaff
const canViewProviders = true

const theme = useTheme()
</script>

<template>
    <div
        v-if="store.role"
        class="apps-list"
    >
        <ServiceApp
            v-if="canViewInvoicing"
            :name="t('auth.apps.invoicing')"
            link="/invoicing/"
        >
            <v-avatar color="primary">
                <v-icon icon="attach_money" />
            </v-avatar>
        </ServiceApp>
        <ServiceApp
            v-if="canViewDBExplorer"
            :name="t('auth.apps.db_explorer')"
            link="/db-explorer/"
        >
            <img
                class="apps-list__goose-img"
                v-if="theme.global.current.value.dark"
                src="@kidzonet/assets/src/goose.svg"
            >
            <img
                class="apps-list__goose-img"
                v-else
                src="@kidzonet/assets/src/goose-primary.svg"
            >
        </ServiceApp>
        <ServiceApp
            v-if="canViewStaff"
            :name="t('auth.apps.staff')"
            link="/staff/"
        >
            <v-avatar color="primary">
                <v-icon icon="people" />
            </v-avatar>
        </ServiceApp>
        <ServiceApp
            v-if="canViewProviders"
            :name="t('auth.apps.subproviders')"
            link="/providers/"
        >
            <v-avatar color="primary">
                <v-icon icon="people_outline" />
            </v-avatar>
        </ServiceApp>
        <ServiceApp
            v-if="canViewAPI"
            :name="t('auth.apps.api')"
            link="/api/"
        >
            <v-avatar color="primary">
                <v-icon icon="laptop" />
            </v-avatar>
        </ServiceApp>
    </div>
</template>

<style>
    .apps-list {
        display: flex;
        justify-content: center;
        margin: 1em 0;

        &__goose-img {
            inline-size: 3em;
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
</style>
