<script lang="ts" setup>
import { inject, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $busEmit } from '@kidzonet/inject-bus-emit'
import { MainMenuInterface } from '../types'

import MiniMenu from './MiniMenu.vue'

import MainMenuListItem from './ListItem.vue'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface DrawerProps {
    menu: MainMenuInterface[]
    mini?: boolean
}

const props = withDefaults(defineProps<DrawerProps>(), {
    mini: false,
    children: [],
})

const highlightedMenuItem = ref('')

const router = useRouter()
const route = useRoute()

const groupIsActive = (name: string) => {
    const itemPosition = route.fullPath.indexOf(name)
    const result = itemPosition > -1
    if (result) {
        if (!highlightedMenuItem.value) {
            highlightedMenuItem.value = name
        }
        return highlightedMenuItem.value === name
    }
    return result
}

const isActiveItem = (menuItem: MainMenuInterface) => {
    return route.path === menuItem.to
}

const busEmit = inject($busEmit)

const click = (item: MainMenuInterface) => {
    if (busEmit && isActiveItem(item)) {
        busEmit('click_on_current_menu_item')
    }
}

const goTo = ({ to = '' }: MainMenuInterface) => {
    if (route.path !== to) {
        router.push(to)
    }
}

const vListGroupClass = (title: string) => {
    const baseClass = 'drawer-menu__item'
    if (groupIsActive(title)) {
        return `${baseClass} drawer-menu__item-active`
    }
    return baseClass
}
</script>

<template>
    <v-list
        nav
        class="drawer-menu"
    >
        <MiniMenu
            v-if="props.mini"
            :menu="props.menu"
        />
        <div
            v-for="item in props.menu"
            :key="item.title"
        >
            <v-list-group
                v-if="item.children"
                :prepend-icon="item.icon"
                active-class="drawer-menu__item-active"
                :class="vListGroupClass(item.title)"
                @click="goTo(item)"
                no-action
            >
                <template #activator>
                    <MainMenuListItem
                        :item="item"
                        @click="click(item)"
                    />
                </template>

                <v-list-item
                    v-for="subItem in (item.children || [])"
                    :key="subItem.title"
                    :to="subItem.to || { name: subItem.name }"
                    :href="item.href"
                    active-class="drawer-menu__subitem-active"
                    class="drawer-menu__subitem"
                    @click="click(subItem)"
                >
                    <v-list-item-title class="drawer-menu__item-title">
                        {{ subItem.title }}
                    </v-list-item-title>
                    <!-- <v-list-item-icon>
                        <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-icon> -->
                </v-list-item>
            </v-list-group>
            <MainMenuListItem
                v-else
                :item="item"
                @click="click(item)"
            />
        </div>
    </v-list>
</template>

<style>
    .drawer-menu {
        text-transform: capitalize;

        &__item {
            color: #fff !important;

            &--active,
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }

            /* vuetify override */
            .v-list-item--active:hover::before,
            .theme--light.v-list-item--active::before {
                opacity: 0;
            }
        }

        &__item-title {
            color: #fff;
            text-transform: capitalize;
        }

        /* vuetify override */
        .v-icon {
            color: #fff !important;
        }

        .v-list-group__header__prepend-icon {/* stylelint-disable-line */
            margin-inline-start: 0 !important;
        }
    }
</style>
