import { aliases, md } from 'vuetify/iconsets/md'
import { fa } from 'vuetify/iconsets/fa'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { IconProps, createVuetify } from 'vuetify'

import theme from '@kidzonet/vuetify-config/isp-admin-theme'
import { defaultVuetifyComponentsStyle } from '@kidzonet/vuetify-config/vuetify'

const kidzonetDashboardAliases = {
    Trash: 'delete',
}

export default createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'kidzonetDashboardIconSet',
        aliases: {
            ...aliases,
            ...kidzonetDashboardAliases,
        },
        sets: {
            fa,
            md,
            kidzonetDashboardIconSet: {
                component: (props: IconProps) => {
                    const iconName = props.icon || ''
                    if (Object.prototype.hasOwnProperty.call(kidzonetDashboardAliases, iconName)) {
                        return md.component({
                            ...props,
                            // @ts-expect-error vuetify types
                            icon: kidzonetDashboardAliases[iconName],
                        })
                    }
                    return md.component(props)
                },
            },
        },
    },
    theme,
    defaults: defaultVuetifyComponentsStyle,
})
