<script lang="ts">
export default {
    name: 'Preloader',
}
</script>

<template>
    <div class="preloader">
        <v-progress-linear
            indeterminate
            color="primary"
            size="100"
            width="10"
        />
    </div>
</template>

<style>
    .preloader {
        position: absolute;
        z-index: 1;
        inset-block-start: 0;
        inset-inline-start: 0;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;

        inline-size: 100%;
        block-size: 100%;

        opacity: 0.3;
        background-color: #ccc;
    }
</style>
