<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@kidzonet/pinia/UserStore'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

import AccountSettings from './Settings/index.vue'
import AccountUserInfo from './UserInfo.vue'
import SessionList from './SessionList/index.vue'

await useLoadLocaleMessages('login')

const { t } = useI18n()
const store = useAuthStore()
</script>

<template>
    <div class="settings">
        <div class="settings__cards">
            <AccountSettings class="settings__card elevation-1">
                <slot name="account-settings" />
            </AccountSettings>
            <AccountUserInfo class="settings__card elevation-1" />
        </div>
        <div class="settings__session-list-card elevation-1">
            <SessionList v-if="store.user && store.user.id" />
        </div>
        <slot />
        <v-btn
            color="primaryButton"
            class="settings__logout-btn white--text"
            @click="store.logout()"
        >
            {{ t('login.logout') }}
        </v-btn>
    </div>
</template>

<style>
    .settings {
        display: flex;
        flex-direction: column;
        justify-content: center;

        max-inline-size: 1200px;
        margin: 0 auto;

        &__cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__logout-btn {
            align-self: center;
            inline-size: 10em;
            margin-block-start: 3em;
        }

        &__session-list-card {
            min-block-size: 200px;
            margin-block-end: 1.5em;
            padding: 2em;
            background-color: var(--widget-background-color);

            @media (max-width: 700px) {
                padding: 1em;
            }
        }

        &__card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            inline-size: 49%;
            margin-block-end: 1.5em;
            padding: 2em;

            background-color: var(--widget-background-color);

            @media (max-width: 700px) {
                inline-size: 100%;
                padding: 1em;
            }
        }
    }
</style>
