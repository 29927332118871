<script lang="ts" setup>
import { useTheme } from 'vuetify'

import { MainMenuItemInterface } from '@kidzonet/vue3-common-layer/src/types'
import CommonLayer from '@kidzonet/vue3-common-layer/src/CommonLayer/index.vue'

interface RedocApiContainerProps {
    back: string | { name: string }
    backButton?: string
    oneLine: boolean
    menu: MainMenuItemInterface[]
}

withDefaults(defineProps<RedocApiContainerProps>(), {
    back: '/',
    backButton: '/',
})

const theme = useTheme()
</script>

<template>
    <CommonLayer
        :menu="menu"
        :root="back"
        :one-line="oneLine"
        class="redoc-api"
    >
        <template #after-logo>
            <div class="redoc-api__logo">
                API
            </div>
        </template>
        <template #content>
            <div class="kidzonet-back-button-container">
                <router-link
                    :class="{
                        'kidzonet-back-button': true,
                        'kidzonet-back-button--dark': theme.global.current.value.dark,
                    }"
                    :to="backButton"
                >
                    ‹  Back
                </router-link>
            </div>
            <router-view />
        </template>
    </CommonLayer>
</template>

<style>
    .redoc-api {
        &__logo {
            transform: translateX(40px);

            display: inline-block;
            align-self: center;

            font-size: 32px;
            font-weight: 600;
            color: #fff;
        }

        .layer__home-link {
            text-decoration: none;
        }

        .layer__logo {
            display: none;
        }

        div[role=search] {
            margin-block-start: 1em;
        }

    }

    .kidzonet-back-button-container {
        display: flex;
        justify-content: flex-start;

        padding: 2em;

        background-color: #fff;
        background-color: rgb(250, 250, 250);
    }

    .kidzonet-back-button {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0.5em 1em;

        font-weight: 700;
        color: white;
        text-decoration: none;

        background-color: rgb(var(--v-theme-primary));
        border-radius: 4px;

        &--dark {
            background-color: rgb(var(--v-theme-surface));
        }
    }
</style>
