<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import getRestApiUrl from '@kidzonet/get-rest-api-url'
import Swagger from './ApiSelector.vue'

const host = computed(() => (new URL(getRestApiUrl())).host)
const route = useRoute()
</script>

<template>
    <div
        class="redoc-api"
    >
        <Swagger
            :scheme="route.name as string"
            :host="host"
        />
    </div>
</template>

<style>
    .redoc-api {
        background-color: #fff;

        & code {
            color: inherit;
            background-color: inherit;
        }

        a[href="https://github.com/Redocly/redoc"] {
            display: none;
        }
    }
</style>
