<script setup lang="ts">
import { provide, ref } from 'vue'
import mitt from 'mitt'
import { NConfigProvider, darkTheme } from 'naive-ui'
import { useTheme } from 'vuetify'

import { $busEmit, $busOff, $busOn } from '@kidzonet/inject-bus-emit'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useDarkTheme, useThemeOverrides } from '@kidzonet/naiveui-use-theme'

const theme = useTheme()

const loaded = ref(false)

await Promise.all([
    useLoadLocaleMessages('common'),
    useLoadLocaleMessages('web_common'),
    fetch('/config.json').then(res => res.json()),
]).then(([_a, _b, config]) => {
    Object.entries(config).forEach(([key, value]) => {
        localStorage.setItem(key, String(value))
    })
    loaded.value = true
})

document.body.classList.add(theme.global.name.value)

const naiveUiTheme = useDarkTheme() ? darkTheme : undefined

const themeOverrides = useThemeOverrides() // declare here not in template to avoid vue warning

const emitter = mitt()

provide($busOn, (name: string, callback?: () => void) => {
    // @ts-expect-error strange mitt type validation
    emitter.on(name, callback)
})
provide($busOff, (name: string, callback?: () => void) => {
    emitter.off(name, callback)
})
provide($busEmit, (name: string, callback?: () => void) => {
    emitter.emit(name, callback)
})
</script>

<template>
    <NConfigProvider
        v-if="loaded"
        :theme="naiveUiTheme"
        :theme-overrides="themeOverrides"
        class="config-provider"
    >
        <div :class="['kidzonet-app', theme.global.name.value]">
            <router-view />
        </div>
        <notifications />
    </NConfigProvider>
</template>

<style>
    html, body, #app, .kidzonet-app, .config-provider {
        block-size: 100%;
        color: var(--text-color);
        background-color: var(--app-background-color);
    }

    .dark {
        background-color: var(--app-background-color);
    }

    .dark a {
        color: #fff;
    }
</style>
