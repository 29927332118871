import { createRouter, createWebHistory } from 'vue-router'

import { authGuardBeforeEachHook } from '@kidzonet/vue3-navigation-guard'
import LoginUrls from '@kidzonet/sso-only-login-routes'
import NotFoundRoute from '@kidzonet/error-404'

const Apps = () => import('./components/apps/ControlRoot.vue')
const AppsList = () => import('./components/apps/list/index.vue')

const Account = () => import('./components/Account.vue')
const RedocRoot = () => import('./components/Redoc/index.vue')
const Redoc = () => import('@kidzonet/vue3-redoc/src/Swagger.vue')

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...LoginUrls,
        {
            path: '/apps',
            component: Apps,
            name: 'home',
            redirect: { name: 'apps-list' },
            meta: { requiresAuth: true },
            children: [
                {
                    path: '',
                    name: 'apps-list',
                    component: AppsList,
                }, {
                    path: 'account',
                    component: Account,
                },
            ],
        }, {
            path: '/api',
            component: RedocRoot,
            redirect: '/api/auth_v1',
            meta: { requiresAuth: true },
            children: [
                {
                    path: 'auth_v1',
                    name: 'auth_v1',
                    component: Redoc,
                }, {
                    path: 'dashboard_v1',
                    name: 'dashboard_v1',
                    component: Redoc,
                }, {
                    path: 'isp_v1',
                    name: 'isp_v1',
                    component: Redoc,
                }, {
                    path: 'userpref',
                    name: 'userpref',
                    component: Redoc,
                }, {
                    path: 'kznctlAuth',
                    name: 'kznctlAuth',
                    component: Redoc,
                }, {
                    path: 'billing',
                    name: 'billing',
                    component: Redoc,
                }, {
                    path: 'dbExplorer',
                    name: 'dbExplorer',
                    component: Redoc,
                },
            ],
        },
        NotFoundRoute,
    ],
})

router.beforeEach(authGuardBeforeEachHook)

export default router
