<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import AppRouterRoot from '@kidzonet/common-app-router-root'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

useLoadLocaleMessages('auth')
const { t } = useI18n()

const menu = computed(() => [{
    to: '/apps',
    title: t('auth.menu.apps'),
    icon: 'list',
}, {
    to: '/apps/account',
    title: t('auth.menu.account'),
    icon: 'home',
}])
</script>

<template>
    <AppRouterRoot
        :menu="menu"
        root="/apps"
    />
</template>

<style>
    .db-explorer {
        &__logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-inline-start: 1em;
        }

        &__logo-img {
            inline-size: 2em;
        }

        &__logo {
            inline-size: 6em;

            @media (max-width: 960px) {
                margin-block-start: 0.5em;
            }

            @media (max-width: 360px) {
                margin-inline-start: 0;
            }
        }

        &__logo-text {
            color: #fff;
            text-decoration: none;
        }

        .layer__home-link {
            display: flex;
        }
    }
</style>
